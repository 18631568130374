import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./slideshow.scss"
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

function SlideShow() {
  const [index, setIndex] = useState(0)
    const { allFile } = useStaticQuery(
      graphql`
        query {
          allFile(
            sort: { fields: name, order: DESC }
            filter: { relativeDirectory: { eq: "slides-100k" } }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(maxWidth: 850, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `
    )

  //Minus 1 for array offset from 0
  const length = allFile.edges.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  const { node } = allFile.edges[index]

  return (
    <div className="slideshow">
      <div className="slideshow__inside">
        <Img
          fluid={node.childImageSharp.fluid}
          key={node.id}
          alt={node.name.replace(/-/g, " ").substring(2)}
        />
      </div>
      <div className="slideshow__nav">
        <div><button className="slideshow__prev" onClick={() => handlePrevious()} title="Previous"><MdChevronLeft /></button></div>
        <div><button className="slideshow__next" onClick={() => handleNext()} title="Next"><MdChevronRight /></button></div>
      </div>
    </div>
  )
}
export default SlideShow
